import React, { useState, useEffect} from "react";
import { useNavigate, BrowserRouter as Router, Routes, Route ,useLocation } from 'react-router-dom';
import './App.css'
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from 'aws-amplify';
import 'aws-amplify/auth/enable-oauth-listener';
import awsconfig from './aws-exports';
import SignUpPage from './SignUpPage'; // Updated name
import AddUserDetails from './AddUserDetails'; // Updated name
import HomePage from './HomePage'; // Updated name
import RingSignIn from './RingSignIn';
import TimeSlider from "./TempTest";
import TopBar from './TopBar';
import Properties from './Properties';
import Account from './Account';
import Subscription from './Subscription';
import ModePage from './ModePage';
import ModeSettings from './ModeSettings';
import FloatingButton from './FloatingButton';
import ChatPage from './ChatPage';
import Redirect from './Redirect';

Amplify.configure(awsconfig);

function App() {
  const location = useLocation();
  const isRootPath = location.pathname === '/';
  const [selectedPill, setSelectedPill] = useState(null);
  const [completedPill, setCompletedPill] = useState(null);
  const navigate = useNavigate();

  const handlePillCompletion = () => {
    setSelectedPill(null);
    navigate('/home');
  };

  return (
    <div>
      {!isRootPath && <TopBar /> }
      {!<FloatingButton/>}  
      <Routes>
        <Route path="/" element={<SignUpPage />} /> {/* Main sign-up page */}
        <Route path="/redirect" element={<AddUserDetails />} /> {/* Page to add user details */}
        <Route path="/home" element={<HomePage selectedPill={selectedPill} setSelectedPill={setSelectedPill} completedPill={completedPill} />} /> {/* Main home page */}
        <Route path="/ring-sign-in" element={<RingSignIn/>} /> {/* Ring sign-in page */}
        <Route path="/temp" element={<TimeSlider />} /> {/* Temperature test page */}
        <Route path="/properties" element={<Properties />} /> {/* Properties page */}
        <Route path="/account" element={<Account />} /> {/* Account page */}
        <Route path="/subscription" element={<Subscription />} /> {/* Subscription page */}
        <Route path="/mode-settings" element={<ModeSettings />} />
        <Route path="/add-user" element={<Redirect />} />
        <Route
          path="/pill/:mode"
          element={<ModePage onCompletion={handlePillCompletion} />}
        />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/chat" element={<ChatPage />} />
      </Routes>
    </div>
  );
}

export default App
