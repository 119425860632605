import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Timer from './Timer';

const actions = [
  'Closing Garage',
  'Setting Ring to Away',
  'Enabling Roomba',
  'Turning off lights',
  'Tuning',
  'Monitoring Your Home'
];

const ModePage = ({ onCompletion }) => {
  const { mode } = useParams();
  const [currentAction, setCurrentAction] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentAction < actions.length) {
      const timer = setTimeout(() => {
        setCurrentAction(currentAction + 1);
      }, 3000); // 3 seconds for each action
      return () => clearTimeout(timer);
    } else {
      onCompletion();
    }
  }, [currentAction, onCompletion]);

  return (
    <div className="mode-page-container">
      <div className="mode-page">
        <div className="message">
          Starting the sequence of actions to set your home to {mode} mode
        </div>
        <div className="actions-container">
          {actions.map((action, index) => (
            <div key={index} className="action">
              <Timer isComplete={index < currentAction} />
              <span className="action-text">{action}</span>
            </div>
          ))}
        </div>
        <button onClick={() => navigate('/home')}>Back to Home Page</button>
      </div>
    </div>
  );
};

export default ModePage;
