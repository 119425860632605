/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://3gqs6rlohfey5n7cpfuw3iua4i.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ez6dpmi63bh37a7jtn4tvxrupm",
    "aws_cognito_identity_pool_id": "us-west-2:04831856-e925-4408-941d-a7a189242c08",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_4tDLvK7NI",
    "aws_user_pools_web_client_id": "49d61nugr4nq1vagu37cddk0td",
    "oauth": {
        "domain": "arrchiwebamplifya3a2c3b4-a3a2c3b4-staging.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://main.d216xouw74je8y.amplifyapp.com/,https://main.d216xouw74je8y.amplifyapp.com/",
        "redirectSignOut": "http://localhost:3000/,https://main.d216xouw74je8y.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
