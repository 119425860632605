// useSignout.js
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { removeUserCookies } from './userCookies';

const useSignout = () => {
    const navigate = useNavigate();

    const handleSignout = async () => {
        try {
            await signOut({ global: true });
            localStorage.clear();
            sessionStorage.clear();
            removeUserCookies();
            navigate('/', { replace: true });
        } catch (error) {
            console.error('Error during sign out:', error);
        }
    };

    return handleSignout;
};

export default useSignout;
