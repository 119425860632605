import React, { useState } from 'react';

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSend = async () => {
    if (input.trim() === '') return;

    const newMessage = { user: 'You', text: input };
    setMessages([...messages, newMessage]);

    const response = await fetch('https://api.openai.com/v1/engines/davinci-codex/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer YOUR_OPENAI_API_KEY`
      },
      body: JSON.stringify({
        prompt: input,
        max_tokens: 150,
      })
    });

    const data = await response.json();
    const botMessage = { user: 'Bot', text: data.choices[0].text.trim() };
    setMessages([...messages, newMessage, botMessage]);
    setInput('');
  };

  return (
    <div className="chat-page">
      <div className="chat-header">Chat</div>
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`chat-message ${message.user.toLowerCase()}`}>
            <strong>{message.user}: </strong>{message.text}
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default ChatPage;
