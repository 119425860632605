export const states = [
    { code: 'AL', name: 'Alabama' },
    { code: 'AK', name: 'Alaska' },
    { code: 'AZ', name: 'Arizona' },
    { code: 'AR', name: 'Arkansas' },
    { code: 'CA', name: 'California' },
    { code: 'CO', name: 'Colorado' },
    { code: 'CT', name: 'Connecticut' },
    { code: 'DE', name: 'Delaware' },
    { code: 'FL', name: 'Florida' },
    { code: 'GA', name: 'Georgia' },
    { code: 'HI', name: 'Hawaii' },
    { code: 'ID', name: 'Idaho' },
    { code: 'IL', name: 'Illinois' },
    { code: 'IN', name: 'Indiana' },
    { code: 'IA', name: 'Iowa' },
    { code: 'KS', name: 'Kansas' },
    { code: 'KY', name: 'Kentucky' },
    { code: 'LA', name: 'Louisiana' },
    { code: 'ME', name: 'Maine' },
    { code: 'MD', name: 'Maryland' },
    { code: 'MA', name: 'Massachusetts' },
    { code: 'MI', name: 'Michigan' },
    { code: 'MN', name: 'Minnesota' },
    { code: 'MS', name: 'Mississippi' },
    { code: 'MO', name: 'Missouri' },
    { code: 'MT', name: 'Montana' },
    { code: 'NE', name: 'Nebraska' },
    { code: 'NV', name: 'Nevada' },
    { code: 'NH', name: 'New Hampshire' },
    { code: 'NJ', name: 'New Jersey' },
    { code: 'NM', name: 'New Mexico' },
    { code: 'NY', name: 'New York' },
    { code: 'NC', name: 'North Carolina' },
    { code: 'ND', name: 'North Dakota' },
    { code: 'OH', name: 'Ohio' },
    { code: 'OK', name: 'Oklahoma' },
    { code: 'OR', name: 'Oregon' },
    { code: 'PA', name: 'Pennsylvania' },
    { code: 'RI', name: 'Rhode Island' },
    { code: 'SC', name: 'South Carolina' },
    { code: 'SD', name: 'South Dakota' },
    { code: 'TN', name: 'Tennessee' },
    { code: 'TX', name: 'Texas' },
    { code: 'UT', name: 'Utah' },
    { code: 'VT', name: 'Vermont' },
    { code: 'VA', name: 'Virginia' },
    { code: 'WA', name: 'Washington' },
    { code: 'WV', name: 'West Virginia' },
    { code: 'WI', name: 'Wisconsin' },
    { code: 'WY', name: 'Wyoming' },
  ];
  

  export const cities = {
    AL: ['Birmingham', 'Montgomery', 'Huntsville', 'Mobile', 'Tuscaloosa'],
    AK: ['Anchorage', 'Fairbanks', 'Juneau', 'Wasilla', 'Sitka'],
    AZ: ['Phoenix', 'Tucson', 'Mesa', 'Scottsdale', 'Flagstaff'],
    AR: ['Little Rock', 'Fayetteville', 'Fort Smith', 'Jonesboro', 'Conway'],
    CA: ['Los Angeles', 'San Francisco', 'San Diego', 'Sacramento', 'Fresno'],
    CO: ['Denver', 'Colorado Springs', 'Aurora', 'Boulder', 'Fort Collins'],
    CT: ['Hartford', 'New Haven', 'Bridgeport', 'Stamford', 'Waterbury'],
    DE: ['Wilmington', 'Dover', 'Newark'],
    FL: ['Miami', 'Orlando', 'Tampa', 'Jacksonville', 'Fort Lauderdale'],
    GA: ['Atlanta', 'Savannah', 'Augusta', 'Macon', 'Columbus'],
    HI: ['Honolulu', 'Hilo', 'Kailua', 'Kaneohe', 'Pearl City'],
    ID: ['Boise', 'Idaho Falls', 'Meridian', 'Pocatello', 'Coeur d Alene'],
    IL: ['Chicago', 'Springfield', 'Rockford', 'Peoria', 'Naperville'],
    IN: ['Indianapolis', 'Fort Wayne', 'Evansville', 'South Bend', 'Bloomington'],
    IA: ['Des Moines', 'Cedar Rapids', 'Davenport', 'Sioux City', 'Waterloo'],
    KS: ['Wichita', 'Topeka', 'Overland Park', 'Olathe', 'Lawrence'],
    KY: ['Louisville', 'Lexington', 'Bowling Green', 'Covington', 'Frankfort'],
    LA: ['New Orleans', 'Baton Rouge', 'Shreveport', 'Lafayette', 'Lake Charles'],
    ME: ['Portland', 'Augusta', 'Bangor', 'Lewiston', 'Auburn'],
    MD: ['Baltimore', 'Annapolis', 'Frederick', 'Rockville', 'Gaithersburg'],
    MA: ['Boston', 'Worcester', 'Springfield', 'Lowell', 'Cambridge'],
    MI: ['Detroit', 'Grand Rapids', 'Lansing', 'Ann Arbor', 'Flint'],
    MN: ['Minneapolis', 'Saint Paul', 'Duluth', 'Rochester', 'Bloomington'],
    MS: ['Jackson', 'Gulfport', 'Hattiesburg', 'Biloxi', 'Tupelo'],
    MO: ['St. Louis', 'Kansas City', 'Springfield', 'Columbia', 'Independence'],
    MT: ['Billings', 'Missoula', 'Bozeman', 'Great Falls', 'Butte'],
    NE: ['Omaha', 'Lincoln', 'Bellevue', 'Grand Island', 'Kearney'],
    NV: ['Las Vegas', 'Reno', 'Carson City', 'Henderson', 'Sparks'],
    NH: ['Manchester', 'Concord', 'Nashua', 'Dover', 'Rochester'],
    NJ: ['Newark', 'Jersey City', 'Trenton', 'Paterson', 'Elizabeth'],
    NM: ['Albuquerque', 'Santa Fe', 'Las Cruces', 'Roswell', 'Rio Rancho'],
    NY: ['New York City', 'Buffalo', 'Rochester', 'Syracuse', 'Albany'],
    NC: ['Charlotte', 'Raleigh', 'Greensboro', 'Winston-Salem', 'Durham'],
    ND: ['Fargo', 'Bismarck', 'Grand Forks', 'Minot', 'Williston'],
    OH: ['Columbus', 'Cleveland', 'Cincinnati', 'Toledo', 'Akron'],
    OK: ['Oklahoma City', 'Tulsa', 'Norman', 'Broken Arrow', 'Edmond'],
    OR: ['Portland', 'Salem', 'Eugene', 'Bend', 'Medford'],
    PA: ['Philadelphia', 'Pittsburgh', 'Allentown', 'Erie', 'Reading'],
    RI: ['Providence', 'Warwick', 'Cranston', 'Pawtucket', 'Woonsocket'],
    SC: ['Charleston', 'Columbia', 'Greenville', 'Myrtle Beach', 'Spartanburg'],
    SD: ['Sioux Falls', 'Rapid City', 'Pierre', 'Aberdeen', 'Brookings'],
    TN: ['Nashville', 'Memphis', 'Knoxville', 'Chattanooga', 'Clarksville'],
    TX: ['Houston', 'Dallas', 'Austin', 'San Antonio', 'Fort Worth'],
    UT: ['Salt Lake City', 'Provo', 'Ogden', 'St. George', 'Logan'],
    VT: ['Burlington', 'Montpelier', 'Rutland', 'Bennington', 'Brattleboro'],
    VA: ['Richmond', 'Virginia Beach', 'Norfolk', 'Chesapeake', 'Arlington'],
    WA: ['Seattle', 'Bellevue', 'Tacoma', 'Spokane', 'Everett', 'North Bend'],
    WV: ['Charleston', 'Morgantown', 'Huntington', 'Parkersburg', 'Wheeling'],
    WI: ['Milwaukee', 'Madison', 'Green Bay', 'Kenosha', 'Racine'],
    WY: ['Cheyenne', 'Casper', 'Laramie', 'Gillette', 'Rock Springs'],
  };
  