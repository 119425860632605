import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, signInWithRedirect, signOut } from 'aws-amplify/auth';
import './App.css';
import Cookies from 'js-cookie';
import { Hub } from "aws-amplify/utils";

const SignUpPage = () => {
  const [user, setUser] = useState(null);
  const [userinfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [customState, setCustomState] = useState(null);
 
  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signInWithRedirect":
          handleSignIn();
          break;
        case "signInWithRedirect_failure":
          setError("An error has occurred during the OAuth flow.");
          break;
        case "customOAuthState":
          setCustomState(payload.data);
          break;
      }
    });

  handleSignIn();
  return unsubscribe;
  }, []);
  
  const handleSignIn = async () => {
    try {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        setUser(currentUser);
        Cookies.set('user', JSON.stringify(currentUser), { expires: 7 });
        const userinfoResponse = await fetchUserInfo(currentUser.userId);
        console.log("User info response:", userinfoResponse);
        if (!userinfoResponse || !userinfoResponse.id) {
          console.log("User not found, creating user...");
          createUser(currentUser);
          navigate('/add-user')
        } else {
          setUserInfo(userinfoResponse);
          Cookies.set('userinfo', JSON.stringify(userinfoResponse), { expires: 7 });
          console.log("User found", userinfoResponse);
          navigateBasedOnUser(userinfoResponse);
        }
      }
    } catch (error) {
      console.error('Error during user sign-in:', error);
    }
  };


  const fetchUserInfo = async (userId) => {
    const userinfoUrl = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/get_user?UserID=${userId}`;
    console.log("User info URL:", userinfoUrl);
    try {
      const response = await fetch(userinfoUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      console.log("User info response:", response);
      if (!response.ok) {
        console.error('Failed to fetch user info');
        return {};
      }
      return await response.json();
      
    } catch (error) {
      console.error('Failed to user info API Call', error);
      return {};
    }
    
  };

  const createUser = (user) => {
    try {
      const apiUrl = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/create_user?UserID=${user.userId}&UserName=${user.username}`;
      fetch(apiUrl, {
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
      });
      console.log("User creation initiated.");
    } catch (error) {
      console.error('Error creating user or setting temperature:', error);
    }
    try{
      
      const apiUrlSet = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/update_user?UserID=${user.userId}`;
      console.log(apiUrlSet);
      fetch(apiUrlSet, {
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
      });
      console.log("Set user temperature initiated.");

    } catch (error) {
      console.error('Error fetching user:', error);
    }
    
  };

  const navigateBasedOnUser = (userinfo) => {
    if (userinfo?.is_registered == true && userinfo?.city && userinfo?.state && userinfo?.zipcode) {
      console.log("User is fully registered");
      navigate('/home');
    } else {
      console.log("User is not registered with nest");
      navigate('/add-user');
    }
  };



  return (
    <div className="signup-container">
      <h1 className="title-text">
        AI assistant <br/>
        to manage your Home<br/>
        signup <br/>
        to free your time and mind
      </h1>
      <div className="button-container">
        <button className="google-btn"  onClick={() => signInWithRedirect({ provider: 'Google' })}>
          Continue with Google
        </button>
        <button className="google-btn" onClick={() => signInWithRedirect({ provider: 'Google' })}>
          Sign Up with Google
        </button>
      </div>
    </div>
  );
};

export default SignUpPage;
