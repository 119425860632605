import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Redirect = () => {
  const location = useLocation();

  useEffect(() => {
    const currentUrl = window.location.href;
    const redirectUrl = `yuttori://yuttori.com/authcallback?redirect_uri=${encodeURIComponent(currentUrl)}`;
    setTimeout(() => {
        window.location.href = redirectUrl;
      }, 1000); // Shorter delay for faster redirect 
  
  }, [location]);

  return (
    <div>
       Successfully added! You will be redirected shortly...
       If you are not redirected, Please click "done" on browser
    </div>
  );
};

export default Redirect;
