import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import TemperatureRangeSlider from "./TemperatureRangeSlider";
import ModePage from './ModePage';
import './App.css'; // Ensure to import your CSS file
import { getUserFromCookies, getUserInfoFromCookies } from './userCookies.js';

const HomePage = ({ selectedPill, setSelectedPill, completedPill }) => {
    const user = getUserFromCookies();
    const userInfoFromCookie = getUserInfoFromCookies();
    const [isEditing, setIsEditing] = useState(false);
    const [userPreferences, setUserPreferences] = useState({
        username: userInfoFromCookie?.user_name || '',
        city: userInfoFromCookie?.city || '',
        zipcode: userInfoFromCookie?.zipcode || '',
        currentTemp: '72°F',
    });
    const [morningTemp, setMorningTemp] = useState(70);
    const [eveningTemp, setEveningTemp] = useState(67);
    const [nightTemp, setNightTemp] = useState(65);
    const [showModePage, setShowModePage] = useState(false); // State to control overlay visibility
    const navigate = useNavigate();

    const fetchUserPreferences = async () => {
        // Mock API call
        if (!userInfoFromCookie?.id) return;
        {
            const userinfoUrl = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/get_preferences?UserID=${userInfoFromCookie?.id}`;
        try {
            const response = await fetch(userinfoUrl, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user preferences');
            }
            const data = await response.json();
            setMorningTemp(Number(data.morning_temp) || 70);
            setEveningTemp(Number(data.evening_temp) || 67);
            setNightTemp(Number(data.night_temp) || 65);
        } catch (error) {
            console.error('Failed to fetch user preferences:', error);
            // Default values are already set in useState initialization
        }

        }
        // Here you should make the real API call to get user preferences

    };

    useEffect(() => {
        if (userInfoFromCookie) {
            fetchUserPreferences();
        }
    }, [userInfoFromCookie]);

    const updateTemperature = async (tempType, newValue) => {
        // Update the state first
        switch (tempType) {
            case 'morning_temp':
                setMorningTemp(newValue);
                break;
            case 'evening_temp':
                setEveningTemp(newValue);
                break;
            case 'night_temp':
                setNightTemp(newValue);
                break;
            default:
                break;
        }

        // Make the API call to update the temperature
        const apiUrl = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/set_temperature?UserID=${userInfoFromCookie.id}&${tempType}=${newValue}`;
        
        fetch(apiUrl, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(error => {
            console.error('Failed to update temperature:', error);
        });
    };

    const handlePillClick = (mode) => {
        setSelectedPill(mode);
        setShowModePage(true); // Show the overlay when a pill is clicked
    };

    const handleOverlayClose = () => {
        setShowModePage(false); // Close the overlay
    };

    return (
        <div className="page-container">
            <div className="info-container">
                <div className="grouped-fields-container">
                    <span>{userPreferences.username}</span>
                    <span>{userPreferences.city}:  {userPreferences.zipcode}</span>
                </div>
                <div className="pills-card">
                    <div className="pills-container">
                        {['Going Out', 'Coming Home', 'Home', 'Vacation'].map((pill) => (
                            <span
                                key={pill}
                                className={`pill ${selectedPill === pill ? 'selected' : ''}`}
                                onClick={() => handlePillClick(pill)}
                                style={{
                                    backgroundColor: selectedPill === pill
                                        ? 'blue'
                                        : completedPill === pill
                                            ? 'green'
                                            : 'grey',
                                    color: 'white',
                                    padding: '10px',
                                    margin: '5px',
                                    borderRadius: '5px',
                                    cursor: 'pointer'
                                }}
                            >
                                {pill}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="cards-container">
                    <TemperatureRangeSlider
                        title="Morning: 6AM to 7PM"
                        temp={morningTemp}
                        footer_text="Ideal to set at 70°F"
                        handleChange={(newValue) => updateTemperature('morning_temp', newValue)}
                    />
                    <TemperatureRangeSlider
                        title="Evening: 7PM to 9PM"
                        temp={eveningTemp}
                        footer_text="Ideal to set at 67°F"
                        handleChange={(newValue) => updateTemperature('evening_temp', newValue)}
                    />
                    <TemperatureRangeSlider
                        title="Night: 9PM to 6AM"
                        temp={nightTemp}
                        footer_text="Ideal to set at 65°F"
                        handleChange={(newValue) => updateTemperature('night_temp', newValue)}
                    />
                </div>
            </div>
            {showModePage && (
                <div className="overlay">
                    <ModePage onCompletion={handleOverlayClose} />
                </div>
            )}
        </div>
    );
};

export default HomePage;
