import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import SnowIcon from '@mui/icons-material/AcUnit';
import HeatIcon from '@mui/icons-material/WbSunny';

const TemperatureRangeSlider = ({ title, footer_text, temp, handleChange }) => {
    const [value, setValue] = useState(temp);

    useEffect(() => {
        setValue(temp);
    }, [temp]);

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
        handleChange(newValue);
    };

    return (
        <Card sx={{ 
            borderRadius: 4,
            padding: 2,
            marginTop: 2,
            borderColor: 'blue',
            borderWidth: '1px',
            borderStyle: 'solid',
            width: '450px'
        }}>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    {title}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SnowIcon style={{ marginRight: '10px', color: 'blue' }} />
                    <Slider
                        value={value}
                        onChange={handleSliderChange}
                        min={60}
                        max={80}
                        step={1}
                        marks={[
                            { value: 65, label: '65°F' },
                            { value: 70, label: '70°F' },
                            { value: 75, label: '75°F' },
                        ]}
                        valueLabelDisplay="auto"
                        style={{ flexGrow: 1 }}
                    />
                    <HeatIcon style={{ marginLeft: '10px', color: 'orange' }} />
                </div>
                <footer style={{ textAlign: 'left' }}>
                    <p style={{ color: 'grey' }}>{footer_text}</p>
                </footer>
            </CardContent>
        </Card>
    );
};

export default TemperatureRangeSlider;
