import React, { useEffect, useState } from 'react';

const Timer = ({ isComplete }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isComplete) {
      let start = Date.now();
      const timer = setInterval(() => {
        const timePassed = Date.now() - start;
        setProgress((timePassed / 3000) * 100); // 3 seconds
        if (timePassed >= 3000) {
          clearInterval(timer);
        }
      }, 20);
      return () => clearInterval(timer);
    }
  }, [isComplete]);

  return (
    <div className="timer">
      <svg viewBox="0 0 36 36" className="circular-chart green">
        <path
          className="circle-bg"
          d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${isComplete ? 100 : progress}, 100`}
          d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        {isComplete && (
          <text x="18" y="20.35" className="tick" textAnchor="middle">
            ✓
          </text>
        )}
      </svg>
    </div>
  );
};

export default Timer;
