import Cookies from 'js-cookie';

// Function to retrieve user from cookies and check if it is not null
export const getUserFromCookies = () => {
    const userFromCookie = Cookies.get('user'); // Retrieve the user string from cookies
    const user = userFromCookie ? JSON.parse(userFromCookie) : null; // Parse the user string if it exists

    if (user) {
        console.log('User information in cookies:', user);
        console.log('Username:', user.username);
        console.log('User ID:', user.userId);
    } else {
        console.log('No user information found in cookies.');
    }

    return user;
};

export const getUserInfoFromCookies = () => {
    const userInfoFromCookie = Cookies.get('userinfo'); // Retrieve the user string from cookies
    const userinfo = userInfoFromCookie ? JSON.parse(userInfoFromCookie) : null; // Parse the user string if it exists

    if (userinfo) {
        console.log('User information in cookies:', userinfo);
    } else {
        console.log('No user information found in cookies.');
    }

    return userinfo;
};


export const removeUserCookies = () => {
    Cookies.remove('user'); // Remove the 'user' cookie
    Cookies.remove('userinfo'); // Remove the 'userinfo' cookie
    console.log('User and user info cookies have been removed.');
};
