import React, { useState } from 'react';
import { Card, CardContent, Typography, MenuItem, FormControl, InputLabel, Select, Grid } from '@mui/material';

const TimeSlider = () => {
    const [times, setTimes] = useState({
        morning: { start: { hour: 6, minute: 0, period: 'AM' }, end: { hour: 12, minute: 0, period: 'PM' } },
        evening: { start: { hour: 12, minute: 0, period: 'PM' }, end: { hour: 6, minute: 0, period: 'PM' } },
        night: { start: { hour: 6, minute: 0, period: 'PM' }, end: { hour: 6, minute: 0, period: 'AM' } }
    });
    const [validationError, setValidationError] = useState('');

    const handleTimeChange = (period, timeOfDay, field, value) => {
        const updatedTimes = {
            ...times,
            [period]: {
                ...times[period],
                [timeOfDay]: { ...times[period][timeOfDay], [field]: value }
            }
        };
        setTimes(updatedTimes);
        validateTimes(updatedTimes);
    };

    const validateTimes = (times) => {
        // Add your validation logic here, setting validationError as needed
        setValidationError('');
    };

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
            <Grid item xs={12} md={8} lg={6}>
                {Object.entries(times).map(([key, value]) => (
                    <TimePeriod key={key} period={key} time={value} onTimeChange={handleTimeChange} />
                ))}
                {validationError && <Typography color="error">{validationError}</Typography>}
            </Grid>
        </Grid>
    );
};

const TimePeriod = ({ period, time, onTimeChange }) => {
    if (!time || !time.start || !time.end) {
        console.error(`Time data is missing for period: ${period}`);
        return <div>Time data is missing for this period.</div>;
    }

    const { start, end } = time;
    const hourOptions = Array.from({ length: 12 }, (_, i) => i + 1);
    const minuteOptions = [0, 15, 30, 45];
    const periodOptions = ['AM', 'PM'];

    return (
        <Card variant="outlined" style={{ marginBottom: 20 }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={2}>
                        <Typography variant="h6">
                            {period.charAt(0).toUpperCase() + period.slice(1)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography variant="subtitle1">Start Time</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Hour</InputLabel>
                                    <Select
                                        value={start.hour}
                                        label="Hour"
                                        onChange={(e) => onTimeChange(period, 'start', 'hour', e.target.value)}
                                    >
                                        {hourOptions.map(hour => <MenuItem key={hour} value={hour}>{hour}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Minute</InputLabel>
                                    <Select
                                        value={start.minute}
                                        onChange={(e) => onTimeChange(period, 'start', 'minute', e.target.value)}
                                    >
                                        {minuteOptions.map(min => <MenuItem key={min} value={min}>{min}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>AM/PM</InputLabel>
                                    <Select
                                        value={start.period}
                                        onChange={(e) => onTimeChange(period, 'start', 'period', e.target.value)}
                                    >
                                        {periodOptions.map(period => <MenuItem key={period} value={period}>{period}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography variant="subtitle1">End Time</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Hour</InputLabel>
                                    <Select
                                        value={end.hour}
                                        label="Hour"
                                        onChange={(e) => onTimeChange(period, 'end', 'hour', e.target.value)}
                                    >
                                        {hourOptions.map(hour => <MenuItem key={hour} value={hour}>{hour}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Minute</InputLabel>
                                    <Select
                                        value={end.minute}
                                        onChange={(e) => onTimeChange(period, 'end', 'minute', e.target.value)}
                                    >
                                        {minuteOptions.map(min => <MenuItem key={min} value={min}>{min}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>AM/PM</InputLabel>
                                    <Select
                                        value={end.period}
                                        onChange={(e) => onTimeChange(period, 'end', 'period', e.target.value)}
                                    >
                                        {periodOptions.map(period => <MenuItem key={period} value={period}>{period}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TimeSlider;
