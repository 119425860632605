import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, CircularProgress } from '@mui/material';
import {getUserFromCookies, getUserInfoFromCookies } from './userCookies.js'


const RingSignIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showOTPField, setShowOTPField] = useState(false);
    const [otp, setOtp] = useState('');
    const [counter, setCounter] = useState(60); // 60 seconds countdown
    const [userInfoCookie, setUserInfo] = useState(getUserInfoFromCookies());

    useEffect(() => {
        let interval = null;
        if (showOTPField && counter > 0) {
            interval = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);
        } else if (counter === 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [showOTPField, counter]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        // Simulate API call to send OTP
        const apiOtp = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/ring-code?id=${userInfoCookie.id}&u=${username}&p=${password}`;
        try {
            const response = await fetch(apiOtp, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
        } catch (error) {
            console.error('Failed to update temperature:', error);
        }
        setTimeout(() => {  // Simulating network request delay
            console.log('Sending OTP for', username);
            setShowOTPField(true);
            setIsSubmitting(false);
        }, 2000);
    };

    const handleOTPSubmit = async (event) => {
        event.preventDefault();
        const apiOtp = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/ring-code?id=${userInfoCookie.id}&u=${username}&p=${password}&o=${otp}`;
        console.log('Verifying OTP', otp);
        setTimeout(() => {  // Simulating network request delay
            // On success, redirect or perform suitable action
            window.location.href = '/add-user'; // Redirect to home or original page
        }, 2000);
    };

    return (
      <Container component="main" maxWidth="xs">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
              <Typography component="h1" variant="h5" align="center" style={{ lineHeight: '1.5' }}>
                  Sign in to your<br />Ring account
              </Typography>
              <form style={{ width: '100%', marginTop: 8 }} onSubmit={showOTPField ? handleOTPSubmit : handleSubmit}>
                  {!showOTPField ? (
                      <>
                          <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="username"
                              label="Username"
                              name="username"
                              autoComplete="username"
                              autoFocus
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              disabled={isSubmitting}
                          />
                          <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              autoComplete="current-password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              disabled={isSubmitting}
                          />
                          <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting || username === '' || password === ''}
                              style={{ margin: '24px 0 16px' }}
                          >
                              {isSubmitting ? <CircularProgress size={24} /> : 'Login'}
                          </Button>
                      </>
                  ) : (
                      <>
                          <Typography variant="body1" gutterBottom style={{ marginTop: 20 }}>
                              OTP sent, it might take a few minutes.
                          </Typography>
                          <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              name="otp"
                              label="Enter Code"
                              type="text"
                              id="otp"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              disabled={counter <= 0}
                          />
                          {counter > 0 ? (
                              <Typography variant="body2" style={{ textAlign: 'center', margin: '10px 0' }}>
                                  Resend code in {counter} seconds
                              </Typography>
                          ) : (
                              <Button onClick={() => { setCounter(60); setShowOTPField(false); }} fullWidth color="secondary">
                                  Resend OTP
                              </Button>
                          )}
                          <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              disabled={otp.length === 0 || counter <= 0}
                              style={{ margin: '8px 0' }}
                          >
                              Submit OTP
                          </Button>
                      </>
                  )}
              </form>
          </div>
      </Container>
  );
  
};

export default RingSignIn;
