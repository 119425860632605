import React from 'react';

const Account = () => {
    return (
        <div>
            <h1>Account Page</h1>
        </div>
    );
};

export default Account;
