import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie'; // Library for cookie handling
import { getUserFromCookies, getUserInfoFromCookies } from './userCookies.js';

const URLTracker = () => {
    const [responseStatus, setResponseStatus] = useState(null);
    const user = getUserFromCookies();
    if (user) {
        // Do something with the user information
        console.log('User is available:', user.id);
    } else {
        // Handle the case where no user information is found
        console.log('No user information available.');
    }
    

    useEffect(() => {
        const handleURLChange = async () => {
            // Get the current URL
            const currentURL = window.location.href;
            // Define the regex pattern to match the desired URL format
            const pattern = /code=([^&]+)&scope=/;
            const match = currentURL.match(pattern);

            if (match && user.id) {
                // Extract the text between 'code=' and '&scope='
                const extractedText = match[1];
                console.log('Extracted Text:', extractedText);

                try {
                    // Call the API endpoint with the extracted code
                    const apiUrl = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/putcode?code=${extractedText}&UserID=${user.id}&UserName=${user.username}`;
                    const response = await fetch(apiUrl);
                    const responseData = await response.json();
                    //Update the response status
                    setResponseStatus(response.status); 
                } catch (error) {
                    console.error('Error:', error);
                    // Handle errors
                }
            } else {
                console.log('URL does not match the specified pattern or user information is missing');
            }
        };

        // Call handleURLChange on initial component mount
        handleURLChange();
    });

    return null; // This component doesn't render anything visible
};

export default URLTracker;
