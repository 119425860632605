import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import 'aws-amplify/auth/enable-oauth-listener';
import Location from './Location.js';
import URLTracker from './URLTracker.js';
import RingSignIn from './RingSignIn';
import useSignout from './useSignout';
import { getUserFromCookies, getUserInfoFromCookies } from './userCookies.js';
import Cookies from 'js-cookie';

const AddUserDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(getUserFromCookies());
    const [trackURL, setTrackURL] = useState(false);
    const [userInfoFromCookie, setUserInfoCookie] = useState(getUserInfoFromCookies() || '');
    const handleSignout = useSignout();             
    const [responseStatus, setResponseStatus] = useState(null);

    useEffect(() => {
        const fetchUserInfoOnLoad = async () => {
            const userinfo = await fetchUserInfo();
            setUserInfoCookie(userinfo);
        };
        fetchUserInfoOnLoad();
    }, []);

    const handleGoogleSignUp = () => {
        setTrackURL(true); // Enable URL tracking when button is clicked
    };

    const handleFormSubmitAndNavigate = async () => {
        console.log('Form submitted:');
        const userinfoResponse = await fetchUserInfo();
        Cookies.set('userinfo', JSON.stringify(userinfoResponse), { expires: 7 });
        navigate('/home');
    };

    const fetchUserInfo = async () => {
        const userinfoUrl = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/get_user?UserID=${user.userId}`;
        try {
            const response = await fetch(userinfoUrl, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (!response.ok) {
                console.error('Failed to fetch user info');
                return {};
            }
            const userInfo = await response.json();
            return userInfo;
        } catch (error) {
            console.error('Failed to user info API Call', error);
            return {};
        }
    };

    const handleLoginSubmit = (code) => {
        console.log('Code submitted:', code);
        // Add your logic for handling the code submission here
    };

    const goToRingSignIn = () => {
        navigate('/ring-sign-in');
    };

    useEffect(() => {
        if (trackURL) {
            const amplifyAddUserUrl = 'https://nestservices.google.com/partnerconnections/9cb043c2-dfd8-4aa4-9988-744b170c7c8d/auth?redirect_uri=https://main.d216xouw74je8y.amplifyapp.com/add-user&access_type=offline&prompt=consent&client_id=550183644991-t3a0sq1lc6hlt67ebtvg9d90pvtldm4j.apps.googleusercontent.com&response_type=code&scope=https://www.googleapis.com/auth/sdm.service';
            window.location.href = amplifyAddUserUrl;
        }
    }, [trackURL]);

    useEffect(() => {
        const checkURLForCode = async () => {
            // Check if the URL matches the pattern `code=someCode&scope=`
            const pattern = /code=([^&]+)&scope=/;
            const currentURL = window.location.href;
            const match = currentURL.match(pattern);

            if (match && match[1] && user.userId) {
                // Extract the code from the URL
                const code = match[1];
                console.log('Extracted Code:', code);
                try {
                    // Call the API endpoint with the extracted code
                    const apiUrl = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/putcode?code=${code}&UserID=${user.userId}&UserName=${user.username}`;
                    const response = await fetch(apiUrl);
                    const responseData = await response.json();
                    setResponseStatus(response.status); // Update the response status
                } catch (error) {
                    console.error('Error:', error);
                    // Handle errors
                }
            } else {
                console.log('URL does not match the specified pattern or user information is missing');
            }
        };

        checkURLForCode();
    }, [location.search, user]);

    return (
        <>
            {trackURL && <URLTracker />}
            {userInfoFromCookie ? (
                <div>
                    <div className="page-container">
                        <h1 className="page-title">Smart Home Setup</h1>
                        <div className="button-container">
                            <button className="device-button" onClick={handleGoogleSignUp}> Add Google Nest </button>
                            
                            <button className="device-button" onClick={goToRingSignIn}>Add Ring</button>
                        </div>
                        <div className="form-container-new">
                            <Location onSubmit={handleFormSubmitAndNavigate} />
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default AddUserDetails;
