import React, { useState } from 'react';
import { Container, Grid, TextField, Autocomplete, Button, CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { states, cities } from './constants'; // Ensure this import points to your constants
import {getUserFromCookies, getUserInfoFromCookies} from './userCookies.js'

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
      },
    },
  },
});

const formatMobile = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const LocationForm = ({ onSubmit }) => {
  const [userCookie, setUser] = useState(getUserFromCookies());
  const [userInfoCookie, setUserInfo] = useState(getUserInfoFromCookies() || {});
  const [username, setUsername] = useState(userInfoCookie.user_name|| '');
  const [selectedState, setSelectedState] = useState(userInfoCookie.state || states[0].code || '');
  const [city, setCity] = useState(userInfoCookie.city || cities[selectedState]?.[0] || '');
  const [zipcode, setZipcode] = useState(userInfoCookie.zipcode || '');
  const [zipError, setZipError] = useState(false);

  const validateMobile = (mobile) => /^\(\d{3}\) \d{3}-\d{4}$/.test(mobile);

  const handleMobileChange = (event) => {
    const formattedMobile = formatMobile(event.target.value);
    //setMobile(formattedMobile);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
 
    try{
      const apiUrlLoc = `https://nasrl9fj9a.execute-api.us-west-2.amazonaws.com/prod/update_location?UserID=${userCookie.userId}&UserName=${username}&selectedState=${selectedState}&city=${city}&zipcode=${zipcode}`; 
      console.log(apiUrlLoc);
      const responseLoc = await fetch(apiUrlLoc, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'no-cors'
      }).then(() => {
        console.log("User location updated successfully");
      }).catch(error => {
        console.error("Error updating user info:", error);
      });
          

    } catch (error) {
        console.error('Error fetching user:', error);
    }
    if (!zipError) {  //validateMobile(mobile) && 
      onSubmit({ username, state: selectedState, city, zipcode });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md">
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            {/* Username Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>


            {/* State Dropdown */}
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                options={states}
                getOptionLabel={(option) => option.name}
                value={states.find((s) => s.code === selectedState) || {}}
                onChange={(event, newValue) => {
                  setSelectedState(newValue.code);
                  setCity(cities[newValue.code]?.[0] || '');
                }}
                renderInput={(params) => <TextField {...params} label="State" />}
              />
            </Grid>

            {/* City Dropdown */}
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                options={cities[selectedState] || []}
                getOptionLabel={(option) => option}
                value={city}
                onChange={(event, newValue) => setCity(newValue || '')}
                renderInput={(params) => <TextField {...params} label="City" />}
              />
            </Grid>

            {/* ZIP Code Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ZIP Code"
                value={zipcode}
                onChange={(e) => {
                  const newZip = e.target.value;
                  setZipcode(newZip);
                  setZipError(!/^\d{5}$/.test(newZip));
                }}
                error={zipError}
                helperText={zipError ? 'ZIP code must be 5 digits' : ''}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" disabled={zipError}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </ThemeProvider>
  );
};

export default LocationForm;
