// TopBar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import useSignout from './useSignout';

const TopBar = () => {
    const handleSignout = useSignout();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="top-bar">
            <div className="left">
            <span className="hamburger-menu" onClick={toggleMenu}><FaBars /></span>
                <a className="home">Home</a>
            </div>
            <div className="right">
                <a className="home">Yuttori</a>
            </div>
            <div className="right">
                <a  className="signout" onClick={() => handleSignout()}>Sign Out</a>
            </div>
            {menuOpen && (
                <div className="dropdown-menu">
                    <Link to="/home" onClick={toggleMenu}>Home</Link>
                    <Link to="/add-user" onClick={toggleMenu}>User Settings</Link>
                </div>
            )}
        </div>
    );
};

export default TopBar;
